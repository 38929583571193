// import dependencies
import React from "react";
import { Link } from "gatsby";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";

// import styles and assets
import logo from "../images/logo-nav-ingeniosamente.png";
import * as styles from "./styles/nav-menu.module.scss";

export default function NavMenu() {
    return (
        <Navbar bg="light" expand="md" fixed="top" className={styles.navBar}>
            <Container>
                <Navbar.Brand href="/">
                    <img
                        src={logo}
                        width="160"
                        height="70"
                        className="d-inline-block align-top"
                        alt="Logo Ingeniosas"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav className={styles.menuItems} fill>
                        <Nav.Item className={`${styles.item} ${styles.mainLink}`}>
                            <Link to="/ninas-emulador" className={styles.navLink}>Emulador</Link>
                        </Nav.Item>
                        <NavDropdown className={styles.item} title="Niñas" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/ninas" className={styles.navLink}>
                                Inspírate
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/grandes-ingeniosas/" className={styles.navLink}>
                                Grandes Ingeniosas
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/orientacion-vocacional" className={styles.navLink}>
                                Orientación vocacional
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/ninas-emulador" className={styles.navLink}>
                                Emulador STEM
                            </NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Item className={`${styles.item} ${styles.mainLink}`}>
                            <Link to="/apoderados" className={styles.navLink}>Apoderados</Link>
                        </Nav.Item>
                        <Nav.Item className={`${styles.item} ${styles.mainLink}`}>
                            <Link to="/docentes" className={styles.navLink}>Docentes</Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}