// import dependencies
import React from "react";
import { Link } from "gatsby";

// import styles and assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../utils/font-awesome';
import logo from "../images/og_hor_color.png";
import logoBofa from "../images/logo-bofa.png";
import logoCorfo from "../images/logo-corfo.png";
import logoGob from "../images/logo-gob.png";
import logoMineduc from "../images/logo-mineduc.png";
import logoSanity from "../images/sanity-logo.svg";
import * as styles from "./styles/footer.module.scss";

export default function Footer() {
    return (
<footer className={styles.footer}>
    <div className="container">
        <div className={`row align-items-center align-self-center ${styles.sponsors}`}>
            <div className={`col-sm-12 ${styles.logoBofa}`}>
                <h4>Esta herramienta llega a ti gracias al apoyo</h4>
                <img src={logoBofa} alt="logo de Bank of America"></img>
            </div>
            <div className={`col-sm-12 ${styles.logoCorfoMineduc}`}>
                <h4 className={styles.colabTitle}>Y la colaboración</h4>
                <div className={`row`}>
                    <div className="col-sm-5 d-flex justify-content-center justify-content-md-end align-items-center">
                        <img className={styles.imgCorfo} src={logoCorfo} alt="logo de Corfo"></img>
                    </div>
                    <div className="col-sm-2 d-flex justify-content-center justify-content-md-center align-items-center">
                        <img className={styles.imgGob} src={logoGob} alt="logo de Corfo"></img>
                    </div>
                    <div className="col-sm-5 d-flex justify-content-center justify-content-md-start">
                        <img className={styles.imgMineduc} src={logoMineduc} alt="logo de Mineduc"></img>
                    </div>
                </div>
            </div>
        </div>
        <div className={`row align-items-center align-self-center ${styles.footerRow}`}>
            <div className="col-sm-12 col-md-6 col-lg-4">
                <img className={styles.logoIng} src={ logo } alt="logo"/>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
                <div className={styles.socials}>
                    <div className="icon">
                        <Link to="https://www.facebook.com/programaingeniosas"><FontAwesomeIcon icon={["fab", "facebook-f"]} /></Link>
                    </div>
                    <div className="icon">
                        <Link to="https://twitter.com/Ingeniosas_cl"><FontAwesomeIcon icon={["fab", "twitter"]} /></Link>
                    </div>
                    <div className="icon">
                        <Link to="https://www.instagram.com/ingeniosas_/"><FontAwesomeIcon icon={["fab", "instagram"]} /></Link>
                    </div>
                </div>
                <div className={styles.textBox}>
                    <p>contacto@ingeniosas.org</p>
                </div>
            </div>
            <div className="col-sm-12 col-md-6 offset-md-3 col-lg-4 offset-lg-0">
                <div className={styles.logoSanity}>
                    <a href="https://www.sanity.io">
                        <img src={ logoSanity } alt="logo de Sanity"/>
                    </a>
                </div>
                <div className={styles.textBox}>
                    <a href="https://www.sanity.io">
                        <p>Structured content powered by Sanity.io</p>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div className={styles.subfooter}>
        <span>Ingeniosamente 2021 - Sitio web por u/p1vergara</span>
    </div>
</footer>
    );
}