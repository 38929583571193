// ------------------------------
// library for font awesome icons 
// ------------------------------
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import {
    faBars, 
    faFlask, 
    faSquareRootAlt, 
    faMicrochip, 
    faLink,
    faArrowRight,
    faArrowLeft,
    faCircle,
    faCaretLeft,
    faCaretRight,
    faAngleDown,
    faTimes,
    faProjectDiagram
} from "@fortawesome/free-solid-svg-icons";
import { 
    faFacebookF, 
    faTwitter, 
    faInstagram 
} from "@fortawesome/free-brands-svg-icons";

library.add(
    faFacebookF,
    faTwitter,
    faInstagram,
    faUser,
    faBars,
    faFlask,
    faSquareRootAlt,
    faMicrochip,
    faLink,
    faArrowRight,
    faArrowLeft,
    faCircle,
    faCaretLeft,
    faCaretRight,
    faAngleDown,
    faTimes,
    faProjectDiagram);