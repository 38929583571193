// import dependencies
import React from "react";

// import styles and assets
import * as styles from "./styles/section.module.scss";

export default function Section({children, color}) {
    switch (color) {
        case "yellow":
            return (
                <section className={styles.sectionYellow}>{children}</section>
            );
        case "orange":
            return (
                <section className={styles.sectionOrange}>{children}</section>
            );
        case "turquoise":
            return (
                <section className={styles.sectionTurquoise}>{children}</section>
            );
        case "red":
            return (
                <section className={styles.sectionRed}>{children}</section>
            );
        case "green":
            return (
                <section className={styles.sectionGreen}>{children}</section>
            );
        case "grey":
            return (
                <section className={styles.sectionGrey}>{children}</section>
            );
        case "white":
            return (
                <section className={styles.sectionWhite}>{children}</section>
            );
        default:
            return (
                <section className={styles.sectionWhite}>{children}</section>
            );
    }
}