// import dependencies
import React, {Fragment} from "react";

// import components
import NavMenu from "./nav-menu";
import Footer from "./footer";

export default function Layout({children}) {
    return (
        <Fragment>
            <NavMenu />
            {children}
            <Footer />
        </Fragment>
    );
}