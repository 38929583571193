// import dependencies
import React from "react";

// import styles and assets
import * as styles from "../styles/separator.module.scss";

export default function SeparatorTopTeal() {
    return (
<div className={ styles.separator }>
    <svg width="1280" height="160" viewBox="0 0 1280 160" fill="none" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
    <g transform="matrix(1.00465,0,0,0.975,-1.3145e-13,0.375001)">
        <path d="M0,45.7L53.333,64.75C106.667,83.8 213.333,121.9 320,118.725C426.667,115.55 533.333,71.1 640,39.35C746.667,7.601 853.333,-11.449 960,7.601C1066.67,26.651 1173.33,83.8 1226.67,112.375L1280,140.95L1280,160L0,160L0,45.7Z" style={{fill: "rgb(238,238,238)"}}/>
    </g>
    <g transform="matrix(1.00465,0,0,1,-1.52212e-13,0.75)">
        <path d="M0,66L53.333,75.525C106.667,85.05 213.333,104.1 320,104.1C426.667,104.1 533.333,85.05 640,88.225C746.667,91.4 853.333,116.8 960,119.975C1066.67,123.15 1173.33,104.1 1226.67,94.575L1280,85.05L1280,161.25L0,161.25L0,66Z" style={{fill: "rgb(250,250,250)"}}/>
    </g>
    </svg>
</div>
    );
}