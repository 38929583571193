// extracted by mini-css-extract-plugin
export const sectionSpacing = "section-module--section-spacing--2Wpy4";
export const rowSpacing = "section-module--row-spacing--2ogAF";
export const rowSpacingSml = "section-module--row-spacing-sml--1DRpP";
export const sectionYellow = "section-module--sectionYellow--3dK2i";
export const sectionOrange = "section-module--sectionOrange--1R_Pj";
export const sectionTurquoise = "section-module--sectionTurquoise--1kCFL";
export const sectionRed = "section-module--sectionRed--14Vx9";
export const sectionGreen = "section-module--sectionGreen--30C0U";
export const sectionGrey = "section-module--sectionGrey--B7XxT";
export const sectionWhite = "section-module--sectionWhite--2qB16";